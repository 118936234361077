import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import "../styleAllServices.css";

import residentialLawnLandscapeGarden from "../../../assets/img/residential-lawn-landscape-garden.png";
import spreaderInLawnMaintenance from "../../../assets/img/lawn-maintenance-fertilizer-spreader.jpg";

export default function ResidentialLawnCare() {
  
  const location = useLocation();

  // Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    const linkTag = document.querySelector('link[rel="canonical"]');
    if (linkTag) {
      linkTag.setAttribute("href", canonicalUrl);
    } else {
      const newLinkTag = document.createElement("link");
      newLinkTag.setAttribute("rel", "canonical");
      newLinkTag.setAttribute("href", canonicalUrl);
      document.head.appendChild(newLinkTag);
    }

    const newTitle = "Residential Lawn Care | BUR-HAN Garden & Lawn Care";
    document.title = newTitle;

    const newDescription =
    "Enhance the beauty and function of your outdoor space with our expert lawn care services. BUR-HAN Garden & Lawn Care provides residential lawn care services, including lawn mowing, fertilizing, liming, aeration, moss control, organic weed control, topdressing, overseeding, and European chafer beetle control.";
    const descriptionMetaTag = document.querySelector('meta[name="description"]');
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", newDescription);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "description");
      newMetaTag.setAttribute("content", newDescription);
      document.head.appendChild(newMetaTag);
    }

    const newKeywords = [
      "residential lawn care, lawn mowing, fertilizing, liming, aeration, moss control, organic weed control, topdressing, overseeding, European chafer beetle control, landscaping services, lawn maintenance, grass care, lawn care services",
    ].join(", ");
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute("content", newKeywords);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "keywords");
      newMetaTag.setAttribute("content", newKeywords);
      document.head.appendChild(newMetaTag);
    }

    const ogTitleTag = document.querySelector('meta[property="og:title"]');
    if (ogTitleTag) {
      ogTitleTag.setAttribute("content", newTitle);
    } else {
      const newOgTitleTag = document.createElement("meta");
      newOgTitleTag.setAttribute("property", "og:title");
      newOgTitleTag.setAttribute("content", newTitle);
      document.head.appendChild(newOgTitleTag);
    }

    const ogDescriptionTag = document.querySelector('meta[property="og:description"]');
    if (ogDescriptionTag) {
      ogDescriptionTag.setAttribute("content", newDescription);
    } else {
      const newOgDescriptionTag = document.createElement("meta");
      newOgDescriptionTag.setAttribute("property", "og:description");
      newOgDescriptionTag.setAttribute("content", newDescription);
      document.head.appendChild(newOgDescriptionTag);
    }
  }, [location.pathname]);

  return (
    <div className="wrapper">
      <Header />
      <div className="serviceItemsHeader">
        <h1 data-aos="zoom-in">Residential Lawn Care</h1>
      </div>
      <div className="serviceItemsMain">
        <h2 data-aos="fade-down">
          "Enhance the beauty and function of your outdoor space with our expert
          lawn care services."
        </h2>
        <hr />
        <h2 className="section-header text-center" data-aos="fade-right">
          Residential Lawn Care Services
        </h2>
        <div className="container pb-5">
          <div className="row">
            <div className="hex-cols" data-aos="zoom-in">
              <div className="hexagon-menu">
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-angles-up"></i>
                      </span>
                      <span className="title">Weekly lawn mowing/edging</span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-square-arrow-up-right"></i>
                      </span>
                      <span className="title">Fertilizing</span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-circle-check"></i>
                      </span>
                      <span className="title">Liming</span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-sort-down"></i>
                      </span>
                      <span className="title">Aeration</span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-bullseye"></i>
                      </span>
                      <span className="title">Moss Control</span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-seedling"></i>
                      </span>
                      <span className="title">
                        {" "}
                        Organic Weed Control Program
                      </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-braille"></i>
                      </span>
                      <span className="title">Topdressing</span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-content">
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-ellipsis-vertical"></i>
                      </span>
                      <span className="title">Overseeding</span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div className="hexagon-item">
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="hex-item">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <a
                    className="hex-content"
                    href="/services/european-chafer-beetle-control"
                  >
                    <span className="hex-content-inner">
                      <span className="icon">
                        <i class="fa-solid fa-bug"></i>
                      </span>
                      <span className="title">
                        European Chafer Beetle Control
                      </span>
                    </span>
                    <svg
                      viewBox="0 0 173.20508075688772 200"
                      width="14.1vw"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
                        fill="#1e2530"
                      ></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* -------------- serviceImages SECTION -------------*/}
        {/* -------------- BANNER SECTION -------------*/}
        <div className="banner-section">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <h3 className="heading" data-aos="fade-right">
                  "Empowering Progress, Igniting <span> Results!" </span>
                </h3>
              </div>
              <div
                className="col-lg-6 col-md-12 col-sm-12"
                data-aos="fade-left"
              >
                <p className="text">
                  "Revitalize your outdoor space with our expert landscaping
                  maintenance services, ensuring a thriving and picturesque
                  environment year-round."
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* -------------- about SECTION -------------*/}
        <section className="section-container">
          <div className="image-container px-5" data-aos="fade-right">
            <img
              src={residentialLawnLandscapeGarden}
              alt="BUR-HAN landscaper using a fertilizer spreader on a large green lawn with scenic mountain views"
              title="Professional Lawn Care: Fertilizer Spreader on a Scenic Green Lawn"
            />{" "}
          </div>
          <div className="text-container px-5" data-aos="fade-up">
            <span className="caption d-block mb-2  font-weight-bold">
              Outstanding Services
            </span>
            <h3 className="headingImageText text-uppercase ">
              Residential Lawn Care
            </h3>
            <p>
              BUR-HAN Garden <span style={{ fontFamily: "roboto" }}>&</span>{" "}
              Lawn Care has been providing residential lawn care services for
              over 28 years to clients across the greater Vancouver area. We
              know that even healthy green lawns in Vancouver's wet climate
              require an experienced team of landscapers that know how to care
              for your lawn and provide all the various services required to
              keep the roots healthy and strong, no matter what point in the
              season. Let us help you transform your lawn into something that is
              not only healthy and beautiful, but something that you can be
              really proud of. Nurture your home's outdoor beauty with our
              residential lawn care services! From regular lawn mowing and
              trimming, to fertilizing, aerating, pH balancing and moss control
              - we've got your lawn perfection covered!
            </p>
          </div>
        </section>
        <section className="section-container">
          <div className="text-container px-5" data-aos="fade-up">
            <h3 className="headingImageText text-uppercase">
              Why choose BUR-HAN for your
              <br /> residential lawn care needs?
            </h3>
            <ul>
              <li>
                <strong>Save Time:</strong> Spend more time with your family and
                leave the hard work to us!
              </li>
              <li>
                <strong>No Equipment to Maintain:</strong> We use the latest
                equipment so you don’t have to worry about maintaining your own.
              </li>
              <li>
                <strong>Save Your Back:</strong> Allow our employees to do the
                bending over and heavy lifting on your behalf.
              </li>
              <li>
                <strong>Peace of Mind:</strong> We never lock our clients into
                contracts to ensure that we provide you with the best possible
                service.
              </li>
              <li>
                <strong>A Better Looking Lawn:</strong> With our experience, we
                can help you get the best looking lawn on the block!
              </li>
            </ul>
          </div>
          <div className="image-container px-5" data-aos="fade-left">
            <img
              src={spreaderInLawnMaintenance}
              alt="Well-maintained residential lawn by BUR-HAN with a garden and trees surrounding a cozy house"
              title="Beautiful Residential Lawn Maintenance by BUR-HAN"
            />
          </div>
        </section>
        {/* -------------- END OF  -------------*/}
        <div className="secondBanner">
          <div className="container" data-aos="fade-down">
            <div className="row">
              <div className="col-md-6 ">
                <h3>Ready to elevate your outdoor experience?</h3>
                <p>
                  Fill out our Get a Quote page, and let our expert landscaping
                  team bring your vision to life with a personalized estimate
                  tailored to your unique preferences and needs.
                </p>
              </div>
              <div className="col-md-6 ">
                <div className="call-to-action text-right">
                  <a href="/contact-us" className="get-a-quote">
                    Get A Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
