import React, { useState, useEffect } from "react";
import bee from "../../assets/img/beeshake.png";
import "./style.css";

export default function Bee() {
  
  const [shaking, setShaking] = useState(true);

  useEffect(() => {
    const shakingBee = () => {
      if (window.scrollY <= 80) {
        setShaking(true);
      } else {
        setShaking(false);
      }
    };
    window.addEventListener("scroll", shakingBee);
    return () => {
      window.removeEventListener("scroll", shakingBee);
    };
  }, []);

  return (
    <div className="bee-container">
      <img
        src={bee}
        className={
          shaking ? "bee-image shakeAnimation" : "bee-image stopAnimation"
        }
        alt="Bee"
      />
    </div>
  );
}
