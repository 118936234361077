import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import "../styleAllServices.css";

export default function GardenCleanups() {

  const location = useLocation();

  // Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    const linkTag = document.querySelector('link[rel="canonical"]');
    if (linkTag) {
      linkTag.setAttribute("href", canonicalUrl);
    } else {
      const newLinkTag = document.createElement("link");
      newLinkTag.setAttribute("rel", "canonical");
      newLinkTag.setAttribute("href", canonicalUrl);
      document.head.appendChild(newLinkTag);
    }

    const newTitle = "Garden Cleanups | Landscaping Services at BUR-HAN Garden & Lawn Care";
    document.title = newTitle;

    const newDescription =
    "Get your property ready for winter with our Garden Cleanups services in Vancouver, British Columbia. BUR-HAN Garden & Lawn Care offers expert leaf removal, lawn care, and garden preparation to keep your outdoor space pristine through the colder months.";
    const descriptionMetaTag = document.querySelector('meta[name="description"]');
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", newDescription);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "description");
      newMetaTag.setAttribute("content", newDescription);
      document.head.appendChild(newMetaTag);
    }

    const newKeywords = [
      "Garden cleanups Vancouver, Leaf removal services North Vancouver, Fall lawn care West Vancouver, Vancouver leaf raking services, Residential leaf cleanup Burnaby, Leaf cleanup company Vancouver BC, Fall yard clean-up West Vancouver",
    ].join(", ");
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute("content", newKeywords);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "keywords");
      newMetaTag.setAttribute("content", newKeywords);
      document.head.appendChild(newMetaTag);
    }

    const ogTitleTag = document.querySelector('meta[property="og:title"]');
    if (ogTitleTag) {
      ogTitleTag.setAttribute("content", newTitle);
    } else {
      const newOgTitleTag = document.createElement("meta");
      newOgTitleTag.setAttribute("property", "og:title");
      newOgTitleTag.setAttribute("content", newTitle);
      document.head.appendChild(newOgTitleTag);
    }

    const ogDescriptionTag = document.querySelector('meta[property="og:description"]');
    if (ogDescriptionTag) {
      ogDescriptionTag.setAttribute("content", newDescription);
    } else {
      const newOgDescriptionTag = document.createElement("meta");
      newOgDescriptionTag.setAttribute("property", "og:description");
      newOgDescriptionTag.setAttribute("content", newDescription);
      document.head.appendChild(newOgDescriptionTag);
    }
  }, [location.pathname]);

  return (
    <div className="wrapper">
      <Header />
      <div className="serviceItemsHeader">
        <h1 data-aos="zoom-in">Garden Cleanups</h1>
      </div>
      <div className="serviceItemsMain">
        <h2 data-aos="fade-down">
          "Enhance the beauty and function of your outdoor space with our expert
          garden cleanups services."
        </h2>
        <div className="row justify-content-md-center mb-5 ">
          <div
            className="col-lg-3 md-5 col-sm-6 col-xs-12"
            data-aos="fade-right"
          >
            <a href="/services/garden-cleanups/leaf-cleanup">
              <div className="servicesCard transfNone">
                <div className="cover ">
                  <h3>Leaf Cleanup</h3>
                </div>
              </div>
            </a>
          </div>
          <div
            className="col-lg-3 offset-lg-1 col-md-5 col-sm-6 col-xs-12"
            data-aos="fade-down"
          >
            <a href="/services/garden-cleanups/garden-cleanup">
              <div className="servicesCard transfNone">
                <div className="cover ">
                  <h3>Garden Cleanup</h3>
                </div>
              </div>
            </a>
          </div>
        </div>
        <hr style={{ marginBottom: "2vw" }} />
        <div className="container-grubgone">
          <div className="row">
            {/* -------------- about SECTION -------------*/}
            {/* -------------- END OF BANNER -------------*/}
            <div className="secondBanner">
              <div className="container" data-aos="fade-down">
                <div className="row">
                  <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-8">
                    <h3 className="grubBannerTitle">
                      Get Ready for Fall with Our Professional Leaf Cleanup
                      Services
                    </h3>
                    <p className="grubBannerText">
                      Don't let the falling leaves take over your lawn. Let
                      BUR-HAN Garden & Lawn Care handle the hard work for you!{" "}
                      <span style={{ color: "var(--yellow-color)" }}>
                        CONTACT US TODAY
                      </span>{" "}
                      to schedule your leaf cleanup and ensure your property
                      stays beautiful throughout the fall season.{" "}
                    </p>{" "}
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-4">
                    <div className="call-to-action text-right">
                      <a
                        href="/contact-us"
                        className="get-a-quote grubBannerButton"
                      >
                        Contact Us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
