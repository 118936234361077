import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import emailjs from "@emailjs/browser";
import { driverslicenseOption, styles } from "../../script";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./style.css";

import one from "../../assets/img/one.svg";
import two from "../../assets/img/two.svg";
import three from "../../assets/img/three.svg";
import four from "../../assets/img/four.svg";
import bhProfessionalGardenMaintenance from "../../assets/img/bh-professional-garden-maintenance-north-vancouver.jpg";
import bhFamilyLawnCareServices from "../../assets/img/bh-family-lawn-care-services-north-vancouver.jpg";
import bhLandscapingVancouver from "../../assets/img/bh-commercial-landscaping-vancouver.jpg";
import bhBackyardGardeLawnDesign from "../../assets/img/bh-backyard-garden-lawn-design-vancouver.jpg";
import spreaderInLawnMaintenance from "../../assets/img/lawn-maintenance-fertilizer-spreader.jpg";
import bhClientConsultation from "../../assets/img/bh-client-consultation-garden-care-north-vancouver.jpeg";

export default function JoinOurTeam() {
  
  const location = useLocation();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const history = useNavigate(); // Get the history object from React Router
  const form = useRef();

  // Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    const linkTag = document.querySelector('link[rel="canonical"]');
    if (linkTag) {
      linkTag.setAttribute("href", canonicalUrl);
    } else {
      const newLinkTag = document.createElement("link");
      newLinkTag.setAttribute("rel", "canonical");
      newLinkTag.setAttribute("href", canonicalUrl);
      document.head.appendChild(newLinkTag);
    }

    const newTitle = "Join Our Team | Landscaping Careers at BUR-HAN Garden & Lawn Care";
    document.title = newTitle;

    const newDescription =
    "Explore career opportunities with BUR-HAN Garden & Lawn Care. Join our team and grow with us in a professional, inclusive, and empowering environment.";
    const descriptionMetaTag = document.querySelector('meta[name="description"]');
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", newDescription);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "description");
      newMetaTag.setAttribute("content", newDescription);
      document.head.appendChild(newMetaTag);
    }

    const newKeywords = [
      "join our team BUR-HAN Garden and Lawn Care, career opportunities landscaping Vancouver, landscaping jobs North Vancouver, professional development in landscaping, landscaping job opportunities Vancouver, diversity and inclusion landscaping jobs, inclusive work environment Vancouver, garden care career North Vancouver, lawn maintenance jobs BC, commercial landscaping careers Vancouver, sustainable landscaping jobs Vancouver, work-life balance landscaping careers, empowering environment landscaping jobs, landscaping company hiring Vancouver, landscaping and garden maintenance jobs, landscaping innovation careers, collaborative landscaping team Vancouver, recognition and rewards landscaping roles, landscaping careers with health benefits, careers at BUR-HAN Garden and Lawn Care"
    ].join(", ");
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute("content", newKeywords);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "keywords");
      newMetaTag.setAttribute("content", newKeywords);
      document.head.appendChild(newMetaTag);
    }

    const ogTitleTag = document.querySelector('meta[property="og:title"]');
    if (ogTitleTag) {
      ogTitleTag.setAttribute("content", newTitle);
    } else {
      const newOgTitleTag = document.createElement("meta");
      newOgTitleTag.setAttribute("property", "og:title");
      newOgTitleTag.setAttribute("content", newTitle);
      document.head.appendChild(newOgTitleTag);
    }

    const ogDescriptionTag = document.querySelector('meta[property="og:description"]');
    if (ogDescriptionTag) {
      ogDescriptionTag.setAttribute("content", newDescription);
    } else {
      const newOgDescriptionTag = document.createElement("meta");
      newOgDescriptionTag.setAttribute("property", "og:description");
      newOgDescriptionTag.setAttribute("content", newDescription);
      document.head.appendChild(newOgDescriptionTag);
    }
  }, [location.pathname]);

  const sendEmail = (e) => {
    e.preventDefault();
    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailInput = form.current["email"].value;
    if (!emailRegex.test(emailInput)) {
      alert("Please enter a valid email address.");
      return;
    }

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_SERVICE_ID, // Your Service ID
        process.env.REACT_APP_EMAIL_TEMPLATE_ID, // Your Template ID
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          setShowSuccessMessage(true); // Show the popup message
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  };

  const handleReload = () => {
    setShowSuccessMessage(false); // Hide the popup message
    history("/join-our-team"); // Navigate to the join our team page
    window.location.reload(); // Reload the page
  };

  return (
    <div className="wrapper">
      <Header />
      <div className="teamHeader">
        <h1>Join Our Team</h1>
      </div>
      <div className="teamMain">
        <h2>Why it would be great to work with us?</h2>
        <hr />
        <p className="careers">
          {" "}
          Email us your resume to:
          <a className="careersLink" href="mailto:hr@bur-han.ca">
            {" "}
            hr@bur-han.ca{" "}
          </a>
          or fill out the form below
        </p>

        <div className="container1">
          <div className="card-left" data-aos="fade-right">
            <div className="card-image sm">
              <img
                src={bhProfessionalGardenMaintenance}
                alt="Dedicated landscaper providing expert garden care services in North Vancouver, BC."
                title="Trusted Garden Care and Maintenance in North Vancouver by BUR-HAN Garden & Lawn Care"
              />
            </div>
            <div className="card-text">
              <p>
                <span style={{ color: "blue" }}>FUTURE OPPORTUNITIES:</span>{" "}
                Joining the <span style={{ color: "blue" }}> BUR-HAN </span>{" "}
                team opens the door to a world of exciting opportunities and
                meaningful growth. Here's why becoming a part of our dynamic
                community is a decision you won't regret. Your journey with{" "}
                <span style={{ color: "blue" }}>BUR-HAN</span> is more than a
                job; it's an investment in your future. By joining our team,
                you're not only becoming a part of something bigger - you're
                becoming a vital contributor to our shared success.
              </p>
            </div>
          </div>
          <div className="card-top" data-aos="fade-up">
            <div className="card-image">
              <img
                src={bhFamilyLawnCareServices}
                alt="Family enjoying a well-maintained lawn by BUR-HAN Garden & Lawn Care in North Vancouver"
                title="Family-Friendly Lawn Care Services in North Vancouver by BUR-HAN Garden & Lawn Care"
              />
            </div>
            <div className="card-text">
              <p>
                <span style={{ color: "blue" }}>PROFESSIONAL DEVELOPMENT:</span>{" "}
                Your growth matters to us. Through ongoing training, mentorship,
                and access to resources, you'll have the chance to enhance your
                skills and advance your career in ways you never thought
                possible.
              </p>
            </div>
          </div>
          <div className="card-right" data-aos="fade-up">
            <div className="card-image">
              <img src={spreaderInLawnMaintenance} alt="" />
            </div>
            <div className="card-text">
              <p>
                <span style={{ color: "blue" }}>RECOGNITION AND REWARDS:</span>{" "}
                Your hard work doesn't go unnoticed. We recognize and reward
                exceptional contributions, providing you with the acknowledgment
                you deserve.{" "}
              </p>
            </div>
          </div>
          <div className="card-bottom" data-aos="fade-left">
            <div className="card-image">
              <img
                src={bhLandscapingVancouver}
                alt="BUR-HAN team delivering top-notch commercial landscaping services in Vancouver."
                title="Vancouver's Premier Commercial Landscaping Experts | BUR-HAN Garden & Lawn Care"
              />
            </div>
            <div className="card-text">
              <p>
                <span style={{ color: "blue" }}>DIVERSITY AND INCLUSION: </span>
                We celebrate diversity and believe that a variety of
                perspectives enriches our work. By joining us, you become a
                vital part of our inclusive family, where every voice matters.
              </p>
            </div>
          </div>
          <div className="card-bottom" data-aos="fade-right">
            <div className="card-image">
              <img
                src={bhBackyardGardeLawnDesign}
                alt="Professional backyard garden and lawn care solutions by BUR-HAN in Vancouver."
                title="Expert Lawn and Garden Design Services for Backyards in Vancouver"
              />
            </div>
            <div className="card-text">
              <p>
                <span style={{ color: "blue" }}>INCLUSIVE CULTURE:</span> Our
                culture is built on respect, empathy, and mutual support. You'll
                find a friendly and inclusive atmosphere that values each
                individual for who they are.
              </p>
            </div>
          </div>
          <div className="card-middle" data-aos="fade-up">
            <div className="card-text">
              <p>
                <span style={{ color: "blue" }}>PERKS:</span> Enjoy monthly
                BBQ's, customized apparel, health benefits, RRSP contributions
                and so much more when you join the BUR-HAN Team! Our team is
                like our family, and we take care of our own and want to ensure
                everyone who works at BUR-HAN feels respected and appreciated
                for all the hard work that they do!
              </p>
            </div>
          </div>
          <div className="card-right" data-aos="fade-left">
            <div className="card-image">
              <img
                src={spreaderInLawnMaintenance}
                alt="Professional lawn maintenance with a fertilizer spreader by BUR-HAN Garden & Lawn Care in Vancouver."
                title="Reliable Lawn Fertilization and Maintenance Services in Vancouver by BUR-HAN Garden & Lawn Care"
              />
            </div>
            <div className="card-text">
              <p>
                <span style={{ color: "blue" }}>IMPACTFUL WORK:</span> Join us
                in making a difference. Your work will have a tangible impact on
                our projects, clients, and the community at large, allowing you
                to see the direct result of your efforts.
              </p>
            </div>
          </div>
          <div className="card-left" data-aos="fade-up">
            <div className="card-image">
              <img
                src={bhClientConsultation}
                alt="BUR-HAN landscaper consulting with a client about custom garden care solutions in North Vancouver."
                title="Personalized Garden Care Services in North Vancouver by BUR-HAN Garden & Lawn Care"
              />
            </div>
            <div className="card-text">
              <p>
                <span style={{ color: "blue" }}> WORK-LIFE BALANCE:</span> We
                believe that a healthy work-life balance is essential for
                sustained success. Our flexible work arrangements and supportive
                culture ensure that you can excel in both your professional and
                personal pursuits.
              </p>
            </div>
          </div>
          <div className="card-bottom" data-aos="fade-left">
            <div className="card-image">
              <img
                src={bhProfessionalGardenMaintenance}
                alt="Professional landscaper maintaining a vibrant garden in Vancouver, BC."
                title="Expert Garden Maintenance Services in North Vancouver by BUR-HAN Garden & Lawn Care"
              />
            </div>
            <div className="card-text">
              <p>
                <span style={{ color: "blue" }}> EMPOWERING ENVIRONMENT:</span>{" "}
                We empower our team members to take ownership of their work.
                You'll have the autonomy to make decisions and contribute your
                best, leading to a sense of accomplishment and pride.
              </p>
            </div>
          </div>
          <div className="card-right" data-aos="fade-right">
            <div className="card-image">
              <img
                src={bhLandscapingVancouver}
                alt="Professional landscaping solutions for commercial properties in Vancouver."
                title="Commercial Landscaping Solutions in Vancouver by BUR-HAN Garden & Lawn Care"
              />
            </div>
            <div className="card-text">
              <p>
                <span style={{ color: "blue" }}>INNOVATION AT HEART: </span>
                We're at the forefront of innovation, and your contributions
                will play a pivotal role in shaping the future. Your ideas will
                be heard, valued, and transformed into reality.{" "}
              </p>
            </div>
          </div>
          <div className="card-middle" data-aos="fade-up">
            <div className="card-text">
              <p>
                <span style={{ color: "blue" }}>COLLABORATIVE EXCELLENCE:</span>{" "}
                We foster an environment where collaboration thrives. Working
                with talented individuals from diverse backgrounds, you'll be a
                part of a team that values your unique perspectives and
                encourages the exchange of ideas.
              </p>
            </div>
          </div>
        </div>

        {/* ----------------- VALUES ----------------- */}
        <section className="ourValues ourvaluesTeam">
          <h2
            className="section-header"
            style={{ marginTop: "0vw" }}
            data-aos="zoom-in"
          >
            OUR VALUES
          </h2>
          <div className="row" style={{ marginBottom: "-1vw" }}>
            <div
              className="col-lg-3 col-md-6 col-sm-6 col-xs-12"
              data-aos="fade-right"
            >
              <div className="content">
                <img src={one} style={{ width: "4.3rem" }} alt="number 1" />
                <h3>Community</h3>
                <p>- We care about nature</p>
                <p>- We care about each other</p>
                <p>- We care about our community</p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-6 col-xs-12"
              data-aos="zoom-in"
            >
              <div className="content ">
                <img src={two} style={{ width: "4.3rem" }} alt="number 2" />
                <h3>Passion</h3>
                <p>- We have fun together</p>
                <p>- We recognize and celebrate our successes</p>
                <p>- We focus on the details and take pride in our work </p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-6 col-xs-12"
              data-aos="fade-left"
            >
              <div className="content">
                <img src={three} style={{ width: "4.3rem" }} alt="number 3" />
                <h3>Progressive</h3>
                <p>- We are collaborative and resourceful</p>
                <p>- We are focused on continuous improvement </p>
                <p>- We are committed to learning and development</p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-sm-6 col-xs-12"
              data-aos-delay="300"
              data-aos="fade-right"
            >
              <div className="content">
                <img src={four} style={{ width: "4.3rem" }} alt="number 4" />
                <h3>Integrity</h3>
                <p>- We are honest in our dealings </p>
                <p>- We are accountable and do what we say</p>
                <p>- We treat others and the environment with respect</p>
              </div>
            </div>
          </div>
        </section>
        {/* -------------- END OF VALUES -------------*/}

        <section id="contact" className="contact joinourteam-form mb-5">
          <div className="container">
            <div className="row " data-aos="fade-up" data-aos-duration="2500">
              <div className="col-lg-4 ">
                <iframe
                  title="BUR-HAN Garden & Lawn Care Location Map"
                  style={{ height: "100%", width: "100%", border: "0" }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2601.4161203935946!2d-123.01846222309891!3d49.30640276907365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548670f2acf0caf5%3A0xa180b9dfb101ef53!2sBUR-HAN%20Garden%20%26%20Lawn%20Care!5e0!3m2!1sen!2sca!4v1707501402244!5m2!1sen!2sca"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="col-lg-8 ">
                <form
                  ref={form}
                  onSubmit={sendEmail}
                  className="php-email-form"
                >
                  <div className="row">
                    <div className="col-md-6 form-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        name="firstName"
                        id="firstName"
                        placeholder="First Name *"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        id="lastName"
                        placeholder="Last Name *"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        id="phone"
                        placeholder="Phone Number *"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mb-3">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Email *"
                        required
                      />
                    </div>
                    <div className="col-md-12 form-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        name="position"
                        id="position"
                        placeholder="Position You Are Interested In *"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        name="startDate"
                        id="startDate"
                        placeholder="Available Start Date : yyyy-mm-dd*"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mb-3">
                      <Select
                        id="driversLicense"
                        name="driversLicense"
                        classNamePrefix="input-contact"
                        className="input-contact"
                        placeholder="Drivers License *"
                        options={driverslicenseOption}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        styles={styles}
                        isSearchable={false}
                        required
                      />
                    </div>
                  </div>
                  <textarea
                    id="previousExperience"
                    name="previousExperience"
                    className="col-md-12 form-group mb-3 form-control"
                    placeholder="Previous Experience in the Field(If Any) - Please Briefly Describe Any Relevant Experience *"
                    rows="4"
                    required
                  />

                  <div className="text-center">
                    <button type="submit" name="submit" id="submit-home">
                      Send
                    </button>
                  </div>
                </form>

                {/* Popup form message */}
                {showSuccessMessage && (
                  <div className="popup active">
                    <div className="popup-content">
                      <i
                        className="fa fa-check main-content__checkmark"
                        id="checkmark"
                      ></i>
                      <h2>Thank you for contacting us!</h2>
                      <h3>
                        We'll get back to you as soon as possible. <br />
                        Follow us on Social:
                      </h3>
                      <div className="social">
                        <a
                          href="https://www.facebook.com/bhgardenandlawncare"
                          className="social d-flex align-items-center"
                        >
                          <i className="bi bi-facebook"></i>
                        </a>
                        <a
                          href="https://www.instagram.com/bhgardenandlawncare"
                          className="social d-flex align-items-center"
                        >
                          <i className="bi bi-instagram"></i>
                        </a>
                        <a
                          href="https://www.youtube.com/user/readyforvideo"
                          className="social d-flex align-items-center"
                        >
                          <i className="bi bi-youtube"></i>
                        </a>
                        <a
                          href="https://www.pinterest.ca/bhgardenandlawn"
                          className="social d-flex align-items-center"
                        >
                          <i className="bi bi-pinterest"></i>
                        </a>
                      </div>
                      <button onClick={handleReload}>
                        <span>Back to the page </span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}
