import React, { useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./style.css";
import { useLocation } from "react-router-dom";

import gardenPavingDesingnVancouver from "../../assets/img/garden-paving-design-north-vancouver.jpeg";
import chaferBeetleLawnDamage from "../../assets/img/chafer-beetle-lawn-damage-repair-vancouver.jpg";
import backyardBeehiveVancouver from "../../assets/img/backyard-beehive-vancouver-pollination.png";
import familyLawnCareServices from "../../assets/img/family-lawn-care-services-vancouver.jpg";
import lawnCareProfessionalLimeTreatment from "../../assets/img/lawn-care-professional-lime-treatment.jpg";
import pollinatorFriendlyFlowerGarden from "../../assets/img/pollinator-friendly-flower-garden-vancouver.jpg";
import cuttingPerennialsVancouver from "../../assets/img/cutting-perennials-vancouver.jpg";
import fallLandscapePath from "../../assets/img/fall-landscape-path.jpeg";
import properGardenWatering from "../../assets/img/proper-garden-watering-techniques-vancouver.png";
import handPickingBlackberries from "../../assets/img/hand-picking-blackberries-vancouver.jpg";
import yellowDaffodilsSnow from "../../assets/img/yellow-daffodils-snow-vancouver.jpg";
import mulchAndSoil from "../../assets/img/bark-mulch-for-garden.png"
import imgLogo from "../../assets/img/bur-hanBadge.png";

export default function Blog() {
  const location = useLocation();

  // Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    const linkTag = document.querySelector('link[rel="canonical"]');
    if (linkTag) {
      linkTag.setAttribute("href", canonicalUrl);
    } else {
      const newLinkTag = document.createElement("link");
      newLinkTag.setAttribute("rel", "canonical");
      newLinkTag.setAttribute("href", canonicalUrl);
      document.head.appendChild(newLinkTag);
    }

    const newTitle =
      "Blog | Landscaping, Lawn Care, and Gardening Tips in Vancouver";
    document.title = newTitle;

    const newDescription =
      "Explore landscaping tips, lawn care advice, and gardening insights tailored for Vancouver and North Vancouver. Learn from BUR-HAN Garden & Lawn Care experts.";
    const descriptionMetaTag = document.querySelector(
      'meta[name="description"]'
    );
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", newDescription);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "description");
      newMetaTag.setAttribute("content", newDescription);
      document.head.appendChild(newMetaTag);
    }

    const newKeywords = [
      "landscaping tips Vancouver,lawn care tips North Vancouver, organic gardening BC, outdoor living ideas Vancouver, garden maintenance tips, seasonal lawn care tips, sustainable landscaping ideas, pest control tips Vancouver, fertilizer guide for lawns, hardscaping ideas North Vancouver, natural fertilizers for healthy soil, bee-friendly gardens Vancouver, chafer beetle control Vancouver, spring garden preparation BC, fall leaf cleanup tips, water-efficient landscaping solutions, modern garden paving ideas, family lawn care services, pollinator-friendly garden designs, blackberry removal guide Vancouver,Learn about sustainable landscaping ideas to create a bee-friendly garden in Vancouver.",
    ].join(", ");
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute("content", newKeywords);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "keywords");
      newMetaTag.setAttribute("content", newKeywords);
      document.head.appendChild(newMetaTag);
    }

    const ogTitleTag = document.querySelector('meta[property="og:title"]');
    if (ogTitleTag) {
      ogTitleTag.setAttribute("content", newTitle);
    } else {
      const newOgTitleTag = document.createElement("meta");
      newOgTitleTag.setAttribute("property", "og:title");
      newOgTitleTag.setAttribute("content", newTitle);
      document.head.appendChild(newOgTitleTag);
    }

    const ogDescriptionTag = document.querySelector(
      'meta[property="og:description"]'
    );
    if (ogDescriptionTag) {
      ogDescriptionTag.setAttribute("content", newDescription);
    } else {
      const newOgDescriptionTag = document.createElement("meta");
      newOgDescriptionTag.setAttribute("property", "og:description");
      newOgDescriptionTag.setAttribute("content", newDescription);
      document.head.appendChild(newOgDescriptionTag);
    }
  }, [location.pathname]);

  return (
    <div className="wrapper">
      <Header />
      <div className="blogHeader">
        <h1 data-aos="zoom-in"> Blog</h1>
      </div>
      <div className="blogMain" data-aos="fade-up">
        <div className="container">
          <section className="featured-posts">
            <div className="cardBlog-columns listfeaturedtag">
              <div className="cardBlog" data-aos="fade-up">
                <a href="/blog/understanding-the-power-of-soils-and-mulch-in-gardening">
                  <div className="row">
                    <div className="col-5 wrapthumbnail">
                      <div
                        className="thumbnail"
                        style={{ backgroundImage: `url(${mulchAndSoil})` }}
                      ></div>
                    </div>
                    <div className="col-7">
                      <div className="cardBlog-block">
                        <h2 className="cardBlog-title mll">
                          Understanding the Power of Soils and Mulch in
                          Gardening
                        </h2>
                        <div className="metafooter">
                          <div className="tagColor c1">landscaping</div>
                          <div className="wrapfooter">
                            <span className="meta-footer-thumb">
                              <img
                                className="author-thumb"
                                src={imgLogo}
                                alt="BUR-HAN logo"
                              />
                            </span>
                            <span className="author-meta">
                              <span className="post-name">
                                By The Bur-Han Team
                              </span>
                              <br />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="cardBlog" data-aos="fade-up">
                <a href="/blog/cutting-back-perennials-in-vancouver-a-gardener's-guide">
                  <div className="row">
                    <div className="col-5 wrapthumbnail">
                      <div
                        className="thumbnail"
                        style={{
                          backgroundImage: `url(${cuttingPerennialsVancouver})`,
                        }}
                      ></div>
                    </div>
                    <div className="col-7">
                      <div className="cardBlog-block">
                        <h2 className="cardBlog-title mll">
                          Cutting Back Perennials in Vancouver: A Gardener's
                          Guide
                        </h2>
                        <div className="metafooter">
                          <div className="tagColor c1">landscaping</div>
                          <div className="wrapfooter">
                            <span className="meta-footer-thumb">
                              <img
                                className="author-thumb"
                                src={imgLogo}
                                alt="BUR-HAN spring"
                              />
                            </span>
                            <span className="author-meta">
                              <span className="post-name">
                                By The Bur-Han Team
                              </span>
                              <br />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="cardBlog" data-aos="fade-up">
                <a href="/blog/the-importance-of-a-fall-leaf-cleanup">
                  <div className="row">
                    <div className="col-5 wrapthumbnail">
                      <div
                        className="thumbnail"
                        style={{ backgroundImage: `url(${fallLandscapePath})` }}
                      ></div>
                    </div>
                    <div className="col-7">
                      <div className="cardBlog-block">
                        <h2 className="cardBlog-title mll">
                          The Importance Of A Fall Leaf Cleanup
                        </h2>
                        <div className="metafooter">
                          <div className="tagColor c1">landscaping</div>
                          <div className="wrapfooter">
                            <span className="meta-footer-thumb">
                              <img
                                className="author-thumb"
                                src={imgLogo}
                                alt="BUR-HAN spring"
                              />
                            </span>
                            <span className="author-meta">
                              <span className="post-name">
                                By The Bur-Han Team
                              </span>
                              <br />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="cardBlog" data-aos="fade-up">
                <a href="/blog/late-fall-gardening-bulbs-to-remove-and-plant-in-november-and-december">
                  <div className="row">
                    <div className="col-5 wrapthumbnail">
                      <div
                        className="thumbnail"
                        style={{
                          backgroundImage: `url(${yellowDaffodilsSnow})`,
                        }}
                      ></div>
                    </div>
                    <div className="col-7">
                      <div className="cardBlog-block">
                        <h2 className="cardBlog-title mll">
                          Late Fall Gardening: Bulbs to Remove and Plant in
                          November and December
                        </h2>
                        <div className="metafooter">
                          <div className="tagColor c1">landscaping</div>
                          <div className="wrapfooter">
                            <span className="meta-footer-thumb">
                              <img
                                className="author-thumb"
                                src={imgLogo}
                                alt="BUR-HAN logo"
                              />
                            </span>
                            <span className="author-meta">
                              <span className="post-name">
                                By The Bur-Han Team
                              </span>
                              <br />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="cardBlog" data-aos="fade-up">
                <a href="/blog/the-essential-guide-to-blackberry-removal-in-greater-vancouver">
                  <div className="row">
                    <div className="col-5 wrapthumbnail">
                      <div
                        className="thumbnail"
                        style={{
                          backgroundImage: `url(${handPickingBlackberries})`,
                        }}
                      ></div>
                    </div>
                    <div className="col-7">
                      <div className="cardBlog-block">
                        <h2 className="cardBlog-title mll">
                          The Essential Guide to Blackberry Removal in Greater
                          Vancouver
                        </h2>
                        <div className="metafooter">
                          <div className="tagColor c1">landscaping</div>
                          <div className="wrapfooter">
                            <span className="meta-footer-thumb">
                              <img
                                className="author-thumb"
                                src={imgLogo}
                                alt="BUR-HAN spring"
                              />
                            </span>
                            <span className="author-meta">
                              <span className="post-name">
                                By The Bur-Han Team
                              </span>
                              <br />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="cardBlog" data-aos="fade-up">
                <a href="/blog/the-essential-guide-to-lawn-fertilization">
                  <div className="row">
                    <div className="col-5 wrapthumbnail">
                      <div
                        className="thumbnail"
                        style={{
                          backgroundImage: `url(${lawnCareProfessionalLimeTreatment})`,
                        }}
                      ></div>
                    </div>
                    <div className="col-7">
                      <div className="cardBlog-block">
                        <h2 className="cardBlog-title mll">
                          The Essential Guide to Lawn Fertilization
                        </h2>
                        <div className="metafooter">
                          <div className="tagColor c1">landscaping</div>
                          <div className="wrapfooter">
                            <span className="meta-footer-thumb">
                              <img
                                className="author-thumb"
                                src={imgLogo}
                                alt="BUR-HAN logo"
                              />
                            </span>
                            <span className="author-meta">
                              <span className="post-name">
                                By The Bur-Han Team
                              </span>
                              <br />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </section>
          <section className="recentBlog-posts">
            <div className="sectionBlog-title">
              <h2>
                <span data-aos="fade-up">More Blog Posts</span>
              </h2>
            </div>
            <div className="cardBlog-columns listrecent">
              <div className="cardBlog" data-aos="fade-left">
                <a href="/blog/creating-a-pollinator-and-native-plant-garden">
                  <img
                    className="img-fluid"
                    src={pollinatorFriendlyFlowerGarden}
                    alt="Vibrant pollinator-friendly flower garden with pink, blue, and yellow blooms in Vancouver."
                    title="Pollinator-Friendly Flower Gardens in Vancouver | BUR-HAN Garden & Lawn Care"
                  />
                  <div className="cardBlog-block">
                    <h2 className="cardBlog-title">
                      Creating a Pollinator and Native Plant Garden
                    </h2>
                    <div className="metafooter">
                      <div className="tagColor1 c1">landscaping</div>
                      <div className="wrapfooter">
                        <span className="meta-footer-thumb">
                          <img
                            className="author-thumb"
                            src={imgLogo}
                            alt="BUR-HAN"
                          />
                        </span>
                        <span className="author-meta">
                          <span className="post-name">By The Bur-Han Team</span>
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="cardBlog" data-aos="fade-left">
                <a href="/blog/responsible-lawn-watering-in-vancouver">
                  <img
                    className="img-fluid"
                    src={properGardenWatering}
                    alt="Watering a vegetable garden with precision for healthy growth in Vancouver"
                    title="Proper Garden Watering Techniques for Healthy Plants in Vancouver | BUR-HAN Garden & Lawn Care"
                  />
                  <div className="cardBlog-block">
                    <h2 className="cardBlog-title">
                      Responsible Lawn Watering in Vancouver
                      <br />
                    </h2>
                    <div className="metafooter">
                      <div className="tagColor1 c1">landscaping</div>
                      <div className="wrapfooter">
                        <span className="meta-footer-thumb">
                          <img
                            className="author-thumb"
                            src={imgLogo}
                            alt="BUR-HAN"
                          />
                        </span>
                        <span className="author-meta">
                          <span className="post-name">By The Bur-Han Team</span>
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="cardBlog" data-aos="fade-left">
                <a href="/blog/garden-maintenance-guide">
                  <img
                    className="img-fluid"
                    src={familyLawnCareServices}
                    alt="Family enjoying a well-maintained backyard with professional lawn care services in Vancouver."
                    title="Family-Friendly Lawn Care Services in Vancouver by BUR-HAN Garden & Lawn Care"
                  />
                  <div className="cardBlog-block">
                    <h2 className="cardBlog-title">
                      Garden Maintenance Guide - SPRING
                    </h2>
                    <div className="metafooter">
                      <div className="tagColor1 c1">landscaping</div>
                      <div className="wrapfooter">
                        <span className="meta-footer-thumb">
                          <img
                            className="author-thumb"
                            src={imgLogo}
                            alt="BUR-HAN"
                          />
                        </span>
                        <span className="author-meta">
                          <span className="post-name">By The Bur-Han Team</span>
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="cardBlog" data-aos="fade-left">
                <a href="/blog/chafer-beetle">
                  <img
                    className="img-fluid"
                    src={chaferBeetleLawnDamage}
                    alt="Chafer beetle damage on a lawn being inspected and repaired in Vancouver by BUR-HAN Garden & Lawn Care."
                    title="Expert Chafer Beetle Lawn Damage Repair Services in Vancouver"
                  />
                  <div className="cardBlog-block">
                    <h2 className="cardBlog-title">
                      The chafer Beetle <br />
                      <br />
                    </h2>
                    <div className="metafooter">
                      <div className="tagColor1 c1">landscaping</div>
                      <div className="wrapfooter">
                        <span className="meta-footer-thumb">
                          <img
                            className="author-thumb"
                            src={imgLogo}
                            alt="BUR-HAN"
                          />
                        </span>
                        <span className="author-meta">
                          <span className="post-name">By The Bur-Han Team</span>
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="cardBlog" data-aos="fade-left">
                <a href="/blog/paving-stone-quality-and-value">
                  <img
                    className="img-fluid"
                    src={gardenPavingDesingnVancouver}
                    alt="Modern garden with elegant paving and lush greenery designed by BUR-HAN Garden & Lawn Care in North Vancouver."
                    title="Modern Garden Paving and Landscape Design Services in North Vancouver"
                  />
                  <div className="cardBlog-block">
                    <h2 className="cardBlog-title">
                      Paving Stone - Quality and Value
                    </h2>
                    <div className="metafooter">
                      <div className="tagColor1 c2">hardscaping</div>
                      <div className="wrapfooter">
                        <span className="meta-footer-thumb">
                          <img
                            className="author-thumb"
                            src={imgLogo}
                            alt="BUR-HAN"
                          />
                        </span>
                        <span className="author-meta">
                          <span className="post-name">By The Bur-Han Team</span>
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="cardBlog" data-aos="fade-left">
                <a href="/blog/saving-the-bees">
                  <img
                    className="img-fluid"
                    src={backyardBeehiveVancouver}
                    alt="A backyard beehive for sustainable pollination and biodiversity in Vancouver, BC."
                    title="Sustainable Backyard Beekeeping for Pollination in Vancouver"
                  />
                  <div className="cardBlog-block">
                    <h2 className="cardBlog-title">
                      Saving the Bees
                      <br />
                      <br />
                    </h2>
                    <div className="metafooter">
                      <div className="tagColor1 c1">landscaping</div>
                      <div className="wrapfooter">
                        <span className="meta-footer-thumb">
                          <img
                            className="author-thumb"
                            src={imgLogo}
                            alt="BUR-HAN"
                          />
                        </span>
                        <span className="author-meta">
                          <span className="post-name">By The Bur-Han Team</span>
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </div>
  );
}
