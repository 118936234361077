import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./style.css";

export default function Projects() {
  
  const location = useLocation();

  // Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    const linkTag = document.querySelector('link[rel="canonical"]');
    if (linkTag) {
      linkTag.setAttribute("href", canonicalUrl);
    } else {
      const newLinkTag = document.createElement("link");
      newLinkTag.setAttribute("rel", "canonical");
      newLinkTag.setAttribute("href", canonicalUrl);
      document.head.appendChild(newLinkTag);
    }

    const newTitle = "Our Projects | Landscaping Services at BUR-HAN Garden & Lawn Care";
    document.title = newTitle;

    const newDescription =
    "Discover BUR-HAN's diverse landscaping projects, including 3D design, paving stones, retaining walls, and plantings. Get a personalized quote to transform your outdoor space";
    const descriptionMetaTag = document.querySelector('meta[name="description"]');
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", newDescription);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "description");
      newMetaTag.setAttribute("content", newDescription);
      document.head.appendChild(newMetaTag);
    }

    const newKeywords = [
      "landscaping projects Vancouver,3D design landscaping services, professional paving stones installation, retaining walls construction Vancouver, turf installation experts, custom plantings landscaping Vancouver, one-time landscaping cleanups, outdoor living projects Vancouver, personalized landscaping quotes Vancouver, landscaping design and build services, paving stones for driveways Vancouver, retaining walls and hardscaping experts, sustainable turf installation services, garden plantings and flower beds, one-time yard cleanup Vancouver,outdoor landscaping solutions, modern 3D landscape designs Vancouver, premium landscaping projects BC, expert lawn care and turf installation, landscape enhancements and cleanups"
    ].join(", ");
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute("content", newKeywords);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "keywords");
      newMetaTag.setAttribute("content", newKeywords);
      document.head.appendChild(newMetaTag);
    }

    const ogTitleTag = document.querySelector('meta[property="og:title"]');
    if (ogTitleTag) {
      ogTitleTag.setAttribute("content", newTitle);
    } else {
      const newOgTitleTag = document.createElement("meta");
      newOgTitleTag.setAttribute("property", "og:title");
      newOgTitleTag.setAttribute("content", newTitle);
      document.head.appendChild(newOgTitleTag);
    }

    const ogDescriptionTag = document.querySelector('meta[property="og:description"]');
    if (ogDescriptionTag) {
      ogDescriptionTag.setAttribute("content", newDescription);
    } else {
      const newOgDescriptionTag = document.createElement("meta");
      newOgDescriptionTag.setAttribute("property", "og:description");
      newOgDescriptionTag.setAttribute("content", newDescription);
      document.head.appendChild(newOgDescriptionTag);
    }
  }, [location.pathname]);

  return (
    <div className="wrapper">
      <Header />
      <div className="projectsHeader">
        <h1 data-aos="zoom-in"> Our Projects</h1>
      </div>
      <div className="projectsMain" data-aos="fade-up">
        <h2 data-aos="fade-down">
          We are a team of passionate individuals who want to help you
        </h2>
        <hr />
        {/* -------------- background image box -------------*/}
        <div className="projectsBox">
          <div className="container row">
            <div className="row">
              <div className="col-4" data-aos="fade-right">
                <a href="/our-projects/3d-design">
                  <div className="box box1">
                    <h4> 3D Design</h4>
                  </div>
                </a>
              </div>
              <div className="col-4" data-aos="fade-down">
                <a href="/our-projects/paving-stones">
                  <div className="box box2">
                    <h4>Paving Stones</h4>
                  </div>
                </a>
              </div>
              <div className="col-4" data-aos="fade-up">
                <a href="/our-projects/retaining-walls">
                  <div className="box box3">
                    <h4> Retaining Walls</h4>
                  </div>
                </a>
              </div>
              <div className="col-4" data-aos="fade-left">
                <a href="/our-projects/turf-installs">
                  <div className="box box4">
                    <h4> Turf Installs</h4>
                  </div>
                </a>
              </div>
              <div className="col-4" data-aos="fade-up">
                <a href="/our-projects/plantings">
                  <div className="box box6">
                    <h4>Plantings</h4>
                  </div>
                </a>
              </div>
              <div className="col-4" data-aos="fade-right">
                <a href="/our-projects/one-time-cleanups">
                  <div className="box box5">
                    <h4>One-Time Cleanups</h4>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="secondBanner">
          <div className="container" data-aos="fade-down">
            <div className="row ">
              <div className="col-md-6 ">
                <h3>Ready to elevate your outdoor experience?</h3>
                <p>
                  Fill out our Get a Quote page, and let our expert landscaping
                  team bring your vision to life with a personalized estimate
                  tailored to your unique preferences and needs.
                </p>
              </div>
              <div className="col-md-6 ">
                <div className="call-to-action text-right">
                  <a href="/contact-us" className="get-a-quote">
                    Get A Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* -------------- end -------------*/}
      </div>
      <Footer />
    </div>
  );
}
