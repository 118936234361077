import React from "react";
import Header from "../../components/Header";
import "./style.css";

export default function NotFound() {
  return (
    <div className="wrapper">
      <section className="sec404">
        <Header />
        <div className="container404">
          <div id="scene404" className="scene404" data-hover-only="false">
            <div className="one404" data-depth="0.9">
              <div className="content404">
                <span className="piece404"></span>
                <span className="piece404"></span>
                <span className="piece404"></span>
              </div>
            </div>
            <div className="two404" data-depth="0.60">
              <div className="content404">
                <span className="piece404"></span>
                <span className="piece404"></span>
                <span className="piece404"></span>
              </div>
            </div>
            <div className="three404" data-depth="0.40">
              <div className="content404">
                <span className="piece404"></span>
                <span className="piece404"></span>
                <span className="piece404"></span>
              </div>
            </div>
            <p className="p404" data-depth="0.50">
              404
            </p>
            <p className="p404" data-depth="0.10">
              404
            </p>
          </div>
          <div className="text404">
            <article className="article404">
              <p>Page Not Found</p>
              <p>
                Uh oh! Looks like you got lost. <br />
                Go back to the homepage if you dare!
              </p>
              <a href="/">
                <button className="button404">i dare!</button>
              </a>
            </article>
          </div>
        </div>
      </section>
    </div>
  );
}
