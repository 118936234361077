import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "../../../../../components/Header";
import Footer from "../../../../../components/Footer";
import "../../../styleAllServices.css";

import overgrownBlackberryBush from "../../../../../assets/img/overgrown-blackberry-bush.jpg";
import img2 from "../../../../../assets/img/professional-landscaping-team-in-action.jpg";
import invasiveBlackberryThorns from "../../../../../assets/img/invasive-blackberry-thorns.jpg";

export default function BlackberryCleanup() {

  const location = useLocation();

  // Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    const linkTag = document.querySelector('link[rel="canonical"]');
    if (linkTag) {
      linkTag.setAttribute("href", canonicalUrl);
    } else {
      const newLinkTag = document.createElement("link");
      newLinkTag.setAttribute("rel", "canonical");
      newLinkTag.setAttribute("href", canonicalUrl);
      document.head.appendChild(newLinkTag);
    }

    const newTitle = "Blackberry Cleanups | Landscaping Services at BUR-HAN Garden & Lawn Care";
    document.title = newTitle;

    const newDescription =
    "";
    const descriptionMetaTag = document.querySelector('meta[name="description"]');
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", newDescription);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "description");
      newMetaTag.setAttribute("content", newDescription);
      document.head.appendChild(newMetaTag);
    }

    const newKeywords = [
""    ].join(", ");
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute("content", newKeywords);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "keywords");
      newMetaTag.setAttribute("content", newKeywords);
      document.head.appendChild(newMetaTag);
    }

    const ogTitleTag = document.querySelector('meta[property="og:title"]');
    if (ogTitleTag) {
      ogTitleTag.setAttribute("content", newTitle);
    } else {
      const newOgTitleTag = document.createElement("meta");
      newOgTitleTag.setAttribute("property", "og:title");
      newOgTitleTag.setAttribute("content", newTitle);
      document.head.appendChild(newOgTitleTag);
    }

    const ogDescriptionTag = document.querySelector('meta[property="og:description"]');
    if (ogDescriptionTag) {
      ogDescriptionTag.setAttribute("content", newDescription);
    } else {
      const newOgDescriptionTag = document.createElement("meta");
      newOgDescriptionTag.setAttribute("property", "og:description");
      newOgDescriptionTag.setAttribute("content", newDescription);
      document.head.appendChild(newOgDescriptionTag);
    }
  }, [location.pathname]);

  return (
    <div className="wrapper">
      <Header />
      <div className="serviceItemsHeader">
        <h1 data-aos="zoom-in">Blackberry Removal Services</h1>
      </div>
      <div className="serviceItemsMain">
        <h2 data-aos="fade-down">
          "Enhance the beauty and function of your outdoor space with our expert
          lawn care services."
        </h2>
        <hr />
        {/* -------------- serviceImages SECTION -------------*/}
        <section className="section-container">
          <div className="image-container px-5" data-aos="fade-right">
            <img
              src={overgrownBlackberryBush}
              alt="Thick overgrowth of invasive blackberry bushes with red stems and green leaves near a fence"
              title="Overgrown Blackberry Bushes in Urban Landscaping"
            />
          </div>
          <div className="text-container px-5" data-aos="fade-up">
            <h3 className="headingImageText text-uppercase">
              Blackberry Removal Services
            </h3>
            <p>
              Blackberry plants are widely known for their delicious fruit, but
              when left unchecked, they can quickly become an invasive,
              troublesome presence in residential and commercial landscapes. At
              BUR-HAN Garden & Lawn Care, we provide professional blackberry
              removal services to restore and maintain the beauty and health of
              your outdoor space. Here’s why removing blackberry plants is
              essential and why our eco-friendly, pesticide-free methods are the
              ideal choice.
            </p>
          </div>
        </section>
        {/* -------------- BANNER SECTION -------------*/}
        <div className="banner-section">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <h3 className="heading" data-aos="fade-right">
                  "Empowering Progress, Igniting <span> Results!" </span>
                </h3>
              </div>
              <div
                className="col-lg-6 col-md-12 col-sm-12"
                data-aos="fade-left"
              >
                <p className="text">
                  "Revitalize your outdoor space with our expert landscaping
                  maintenance services, ensuring a thriving and picturesque
                  environment year-round."
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* -------------- about SECTION -------------*/}
        <section className="section-container">
          <div className="image-container px-5" data-aos="fade-right">
            <img
              src={invasiveBlackberryThorns}
              alt="Close-up of invasive blackberry bush with thorny red stems and green leaves."
              title="Invasive Blackberry Bush with Thorny Stems and Green Foliage"
              style={{ height: "44vw" }}
            />
          </div>
          <div className="text-container px-4" data-aos="fade-up">
            <h3 className="headingImageText text-uppercase ">
              Why Remove Blackberry Plants?
            </h3>
            <ul>
              <li>
                <strong>Invasive Growth: </strong>
                Blackberry plants spread aggressively, often overwhelming other
                plants and turning well-maintained landscapes into dense,
                tangled thickets. Their fast growth rate allows them to dominate
                gardens, lawns, and even natural habitats, impacting plant
                diversity and making it challenging to keep outdoor spaces under
                control.
              </li>
              <li>
                <strong>Damage to Native Ecosystems: </strong>
                Invasive blackberry species, such as the Himalayan blackberry,
                compete with native plants for vital resources. This
                displacement affects biodiversity and disrupts local ecosystems,
                reducing the habitats and food sources for native wildlife.
              </li>
              <li>
                <strong> Difficult to Control: </strong>
                Once established, blackberry plants are notoriously hard to
                remove. Their robust root systems allow them to regenerate even
                after pruning, requiring specialized techniques for complete
                eradication. Without professional intervention, they can quickly
                regrow and spread across your property.
              </li>
              <li>
                <strong> Hazardous Thorns: </strong>
                Blackberry plants are equipped with sharp thorns that can pose
                injury risks for humans, pets, and wildlife. This can make
                outdoor spaces less enjoyable and safe, especially for families
                with young children and pets.
              </li>
              <li>
                <strong>Fire Hazard: </strong>
                In dry conditions, dense blackberry thickets become a potential
                fire hazard, with dry canes and leaves that ignite easily.
                Removing these plants reduces the risk of fast-spreading
                wildfires, helping to protect properties and natural landscapes
                alike.
              </li>
              <li>
                <strong> Aesthetic Impact: </strong>
                Uncontrolled blackberry growth can quickly reduce the visual
                appeal of your property, creating an overgrown, unmanaged
                appearance. Removing these plants helps restore the beauty and
                usability of your landscape, creating a clean, inviting outdoor
                space
              </li>
              <li>
                <strong> Property Value: </strong>
                Maintained landscapes enhance property value, but blackberry
                overgrowth can detract from this, making outdoor areas less
                appealing and harder to manage. Professional removal preserves
                your landscape’s value and aesthetic.
              </li>
            </ul>
          </div>
        </section>
        <section className="section-container">
          <div className="text-container px-5" data-aos="fade-up">
            <h3 className="headingImageText text-uppercase">
              Our Eco-Friendly Approach to Blackberry Removal
            </h3>
            <ul>
              <li>
                <strong>No Pesticides - 100% Natural Removal:</strong>
                We are committed to eco-friendly practices that protect both the
                environment and your landscape. By avoiding chemical pesticides,
                we ensure that your soil, water, and surrounding plants remain
                uncontaminated. Our pesticide-free approach is safe for
                children, pets, and wildlife, offering you a cleaner, toxin-free
                outdoor space.
              </li>
              <li>
                <strong>Effective Manual Techniques: </strong>
                Our experienced team uses specialized tools to dig out
                blackberry roots and meticulously prune plants at the source,
                ensuring complete removal while preventing regrowth. By
                targeting the root system, we achieve effective long-term
                control without the need for chemicals.
              </li>
              <li>
                <strong>Preserving Your Landscape's Natural Beauty: </strong>
                Our non-invasive methods remove blackberry plants without
                damaging other vegetation. We take special care to preserve the
                aesthetic appeal and health of your landscape, allowing native
                plants to thrive while eliminating unwanted blackberry growth.
              </li>
            </ul>
            <p style={{ paddingLeft: "1vw" }}>
              At BUR-HAN Garden & Lawn Care, we understand that effective
              blackberry removal must also be environmentally conscious. That’s
              why our team uses 100% natural, pesticide-free methods that focus
              on digging and pruning techniques. Here’s what sets our approach
              apart:
            </p>
          </div>
          <div className="image-container px-5" data-aos="fade-left">
            <img
              src={img2}
              alt="landscape maintenance"
              style={{ height: "41vw" }}
            />
          </div>
        </section>
        <section className="section-container">
          <div
            className="text-container"
            style={{
              flexBasis: "calc(90% - 0.5vw)",
              maxWidth: "calc(90% - 0.5vw)",
            }}
            data-aos="fade-up"
          >
            <h3 className="headingImageText text-uppercase">
              Choose BUR-HAN Garden & Lawn Care for Safe, Sustainable Blackberry
              Removal{" "}
            </h3>
            <p>
              With our professional blackberry removal services, BUR-HAN Garden
              & Lawn Care combines expertise with a dedication to
              sustainability. Our eco-friendly, pesticide-free approach means
              you can enjoy a beautiful, well-maintained landscape that’s safe
              for your family, pets, and the local ecosystem. Contact us today
              to reclaim your outdoor space from blackberry overgrowth while
              supporting a healthier, more sustainable environment.
            </p>
          </div>
        </section>
        {/* -------------- END OF BANNER -------------*/}
        <div className="secondBanner" style={{ marginTop: "0" }}>
          <div className="container" data-aos="fade-down">
            <div className="row">
              <div className="col-md-6 ">
                <h3 className="sndheading">
                  Get Started <span>Today!</span>
                </h3>
                <p>
                  Fill out our Get a Quote page, and let our expert landscaping
                  team bring your vision to life with a personalized estimate
                  tailored to your unique preferences and needs.
                </p>
              </div>
              <div className="col-md-6 ">
                <div className="call-to-action text-right">
                  <a href="/contact-us" className="get-a-quote">
                    Get A Quote
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
