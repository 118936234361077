import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import "../styleAllBlogs.css";

import beeSafeBadge from "../../../assets/img/partners/bee-safe-pesticide-free-badge.png";
import beeOnHand from "../../../assets/img/bee-on-hand-vancouver.jpeg";
import beekeepingFrame from "../../../assets/img/beekeeping-frame-honeycomb-vancouver.png";
import beeHoneycombCloseup from "../../../assets/img/bee-honeycomb-closeup-vancouver.jpeg";

export default function Bees() {

  const location = useLocation();

  // Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    const linkTag = document.querySelector('link[rel="canonical"]');
    if (linkTag) {
      linkTag.setAttribute("href", canonicalUrl);
    } else {
      const newLinkTag = document.createElement("link");
      newLinkTag.setAttribute("rel", "canonical");
      newLinkTag.setAttribute("href", canonicalUrl);
      document.head.appendChild(newLinkTag);
    }

    const newTitle = "Saving The Bees Blog";
    document.title = newTitle;

    const newDescription =
      "Discover the importance of organic landscaping for bees and our ecosystem. Learn about pesticide-free solutions, preserving biodiversity, soil health, and more. Choose organic practices to support bee populations and create a sustainable environment.";
    const descriptionMetaTag = document.querySelector('meta[name="description"]');
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", newDescription);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "description");
      newMetaTag.setAttribute("content", newDescription);
      document.head.appendChild(newMetaTag);
    }

    const newKeywords = [
      "Saving bees in Vancouver, Bee conservation Vancouver, Bee-friendly landscaping in Vancouver, Sustainable landscaping for pollinators, Organic landscaping practices, Pesticide-free gardening, Eco-friendly landscaping for bees, Bee-friendly plants Vancouver, Vancouver pollinator preservation, How to save bees through landscaping in Vancouver, Best pollinator-friendly plants for Vancouver gardens,Eco-friendly gardening tips for saving bees,Sustainable landscaping practices in North Vancouver,Organic gardening solutions for pollinators in Vancouver,Bee conservation tips for Vancouver homeowners,Creating a bee-friendly garden in Vancouver,Top pollinator plants for West Vancouver gardens,Pesticide-free landscaping practices for biodiversity,How organic soil health benefits bees",
    ].join(", ");
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute("content", newKeywords);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "keywords");
      newMetaTag.setAttribute("content", newKeywords);
      document.head.appendChild(newMetaTag);
    }

    const ogTitleTag = document.querySelector('meta[property="og:title"]');
    if (ogTitleTag) {
      ogTitleTag.setAttribute("content", newTitle);
    } else {
      const newOgTitleTag = document.createElement("meta");
      newOgTitleTag.setAttribute("property", "og:title");
      newOgTitleTag.setAttribute("content", newTitle);
      document.head.appendChild(newOgTitleTag);
    }

    const ogDescriptionTag = document.querySelector('meta[property="og:description"]');
    if (ogDescriptionTag) {
      ogDescriptionTag.setAttribute("content", newDescription);
    } else {
      const newOgDescriptionTag = document.createElement("meta");
      newOgDescriptionTag.setAttribute("property", "og:description");
      newOgDescriptionTag.setAttribute("content", newDescription);
      document.head.appendChild(newOgDescriptionTag);
    }
  }, [location.pathname]);

  return (
    <div className="wrapper">
      <Header />
      <div className="beforeSection"></div>
      <div className="sectionBlogpost savingbees">
        <a href="/blog" className="backPosts">
          <i className="bi bi-arrow-left"></i>
          Back
        </a>
        <article>
          <header>
            <h1>
              <div className="nth-line-1" data-aos="fade-up">
                Saving The Bees
              </div>
              <div className="nth-line-2" data-aos="fade-up">
                and Our Ecosystem
              </div>
            </h1>
            <p className="lead" data-aos="fade-right"></p>
          </header>
          <hr />
          <section data-aos="fade-right">
            <p>
              Bees are facing numerous threats, including habitat loss,
              pesticide exposure, and climate change. Traditional landscaping
              practices often involve the use of chemical fertilizers,
              herbicides, and pesticides that can be harmful to bees and other
              beneficial insects. By choosing a landscaping company that
              prioritizes organic products, you contribute to creating a safer
              environment for these vital pollinators.
            </p>
            <p style={{ textTransform: "uppercase" }}>
              <span> Organic Landscaping : Sustainable Solutions</span>
            </p>
            <p>
              <span>1. Opting For Pesticide-Free: </span>
              Organic landscaping relies on natural methods for pest control,
              avoiding the use of harmful chemical pesticides. This not only
              protects bees but also contributes to a healthier overall
              ecosystem.
            </p>
            <p>
              <span>2. Preserving Biodiversity: </span>
              Organic landscaping promotes biodiversity by creating habitats
              that support a variety of plant and insect species. Bees rely on
              diverse floral resources for their survival, and organic
              landscapes often feature a wide range of native plants that
              attract and nourish these pollinators. By preserving biodiversity,
              organic landscaping contributes to the overall health of
              ecosystems and helps sustain bee populations.
            </p>
            <img
              src={beeOnHand}
              alt="Close-up of a bee resting on a hand in Vancouver."
              title="Bee Conservation Efforts in Vancouver | Close-Up of a Bee on Hand"
              width={"90% !important"}
            />
            <p>
              <span>3. Soil Health: </span>
              Organic practices and proper cultivation enhance the soil
              fertility and structure of soil. These practices also reduce weeds
              without the use of chemical pesticides. A key aspect of this
              health is the bustling microbial activity within the soil.
              Research shows that healthy soil is teeming with billions of
              bacteria, fungi, and other microbes that play essential roles in
              decomposing organic matter, cycling nutrients, and forming
              symbiotic relationships with plant roots. This microbial activity
              not only helps in the formation of soil structure, improving its
              aeration and water retention capabilities, but also in converting
              organic compounds into forms that plants can easily absorb. As a
              result, healthy soil promotes the growth of diverse plants that
              provide food and habitat for bees and other pollinators, creating
              a sustainable ecosystem. The presence of a diverse microbial
              community in the soil is an indicator of its health and fertility,
              underlining the importance of organic practices that support these
              microscopic life forms crucial for plant growth and environmental
              balance.
            </p>
            <p>
              <span>4. Pollinator-Friendly Plants: </span>
              Organic landscaping often involves selecting plants that are native and well-suited to local ecosystems. Explore our <a href="/services/design-and-modelling" style={{color: "var(--yellow-color)"}}>landscape design and modelling services</a> to create a pollinator-friendly garden. These plants can
              attract and support pollinators, including bees, creating a more
              bee-friendly environment.
            </p>
            <p>
              <span>5. Promoting Environmental Stewardship: </span>
              Opting for organic landscaping reflects a commitment to
              environmental stewardship and sustainable living. By making conscientious choices about the products and practices used in landscaping, such as opting for our <a href="/services/softscape-installs-maintenance" style={{color: "var(--yellow-color)"}}>softscape installation services</a>, you can promote biodiversity and environmental stewardship, individuals demonstrate their dedication to
              protecting the planet and its inhabitants, including bees. This
              ethos of responsibility towards nature can inspire broader changes
              in societal attitudes and behaviors.
            </p>
            <img
              src={beeSafeBadge}
              alt="Saving bees through sustainable landscaping practices in Vancouver"
              title="be pesticide free"
              width={"70% !important"}
              style={{ marginLeft: "10%" }}
            />
            <p>
              <span>5. Reduced Environmental Impact: </span>
              Conventional landscaping practices can lead to chemical runoff,
              negatively impacting water sources and aquatic life. Organic
              landscaping minimizes this environmental impact, contributing to
              cleaner water and a healthier planet.
            </p>
            <p>
              <span>6. Supporting Biodiversity: </span>
              Biodiversity is essential for the overall health of ecosystems.
              Bees, as pollinators, contribute to the diversity of plant life,
              ensuring the survival of various species. By choosing a
              landscaping company that values organic practices, you actively
              participate in the promotion of biodiversity and the preservation
              of natural habitats.
            </p>
            <p>
              <span>7. Educating and Raising Awareness: </span>
              Opting for an organic landscaping company not only benefits your
              immediate surroundings but also serves as a way to educate others
              about the importance of sustainable practices. By sharing your
              choice and the positive impact it has on the environment, you
              contribute to a broader movement towards eco-friendly landscaping.
            </p>
            <img
              src={beeHoneycombCloseup}
              alt="Close-up view of bees working on a honeycomb in Vancouver."
              title="Detailed Look at Bees on Honeycomb in Vancouver"
              width={"90% !important"}
            />
            <p>
              <span>
                The Vital Role of Bees in Our Survival: <br />
              </span>
              Bees are indispensable to our survival and the health of
              ecosystems due to their role as primary pollinators.
            </p>
            <p>
              <span>
                Critical for Food Production:
                <br />{" "}
              </span>{" "}
              Bees contribute to the pollination of one-third of global food crops, ensuring the production of fruits, vegetables, nuts, and oilseeds. Learn how our <a href="/blog/creating-a-pollinator-and-native-plant-garden" style={{color: "var(--yellow-color)"}}>pollinator-friendly garden tips</a> can support these essential pollinators.
            </p>
            <p>
              <span>
                Global Food Security: <br />{" "}
              </span>
              About one-third of our food relies on pollination, with bees
              responsible for pollinating approximately 75% of leading food
              crops. Their decline would lead to food shortages and higher
              prices, affecting global food security.
            </p>
            <p>
              <span>
                Ecosystem Stability: <br />
              </span>
              By pollinating a diverse range of plants, bees support ecosystem
              balance, providing habitat and sustenance for various organisms
              and essential ecological services.
            </p>
            <p>
              <span>
                Economic Value: <br />{" "}
              </span>
              Bees have significant economic worth, contributing billions of
              dollars annually to global agriculture through their pollination
              services and the production of bee-related products.
            </p>
            <img
              src={beekeepingFrame}
              alt="A beekeeper inspecting a honeycomb frame in Vancouver."
              title="Beekeeping Insights: Honeycomb Frame in Vancouver"
              width={"90% !important"}
            />
            <p>
              <span>
                Current Decline of Bees: <br />
              </span>
              Bees are indispensable to our ecosystems and our very survival.
              Here's why:
            </p>
            <p>
              <span>Habitat Loss: </span> Urbanization, agriculture, and
              deforestation destroy bee habitats, reducing forage and nesting
              sites.
            </p>
            <p>
              <span>Pesticide Exposure: </span>Chemical pesticides weaken bee
              health, affecting their immune systems, navigation, and foraging
              abilities.
            </p>
            <p>
              <span>Parasites and Diseases: </span>Bees suffer from parasites
              like Varroa mites, diseases, and viral infections, leading to
              population declines and colony collapse.
            </p>
            <p>
              <span>Climate Change: </span>Altered climate conditions disrupt
              the timing of flowering and pollination, exacerbating other
              stressors like habitat loss.
            </p>
            <p>
              <span>Lack of Floral Diversity: </span>Monoculture practices limit
              access to diverse floral resources, affecting bees' nutrition and
              overall health.
            </p>
            <p>
              {" "}
              <span>Conclusion: </span>
              Choosing a landscaping company that prioritizes organic products
              is a small yet impactful step towards creating a more sustainable
              and bee-friendly environment. By supporting practices that value
              the well-being of these essential pollinators, you play a part in
              preserving the delicate balance of nature and ensuring a greener
              future for generations to come. Make the buzz-worthy choice -
              choose organic for the bees! Check out our{" "}
              <a
                href="https://myhive.alveole.buzz/bur-han-garden-lawn-care"
                style={{ color: "var(--yellow-color)", textDecoration: "none" }}
              >
                BUR-HAN My Hive Page
              </a>{" "}
              for updates on our beehive!!
            </p>
          </section>
        </article>
        <div className="secondBanner">
          <div className="container" data-aos="fade-down">
            <div className="row">
              <div className="col-md-6">
                <h3>Ready to elevate your outdoor experience?</h3>
                <p>
                  Fill out our contact page, and let our expert landscaping team
                  bring your vision to life with a personalized estimate
                  tailored to your unique preferences and needs.
                </p>
              </div>
              <div className="col-md-6 ">
                <div className="call-to-action text-right">
                  <a href="/contact-us" className="get-a-quote">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
