import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import beesFlying from "../../../assets/img/load.gif";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import "../styleAllProjects.css";

export default function TurfInstalls() {

  const location = useLocation();

  // Meta Tag changes
  useEffect(() => {
   const canonicalUrl = `https://bur-han.ca${location.pathname}`;
   const linkTag = document.querySelector('link[rel="canonical"]');
   if (linkTag) {
     linkTag.setAttribute("href", canonicalUrl);
   } else {
     const newLinkTag = document.createElement("link");
     newLinkTag.setAttribute("rel", "canonical");
     newLinkTag.setAttribute("href", canonicalUrl);
     document.head.appendChild(newLinkTag);
   }
 
   const newTitle = "Turf Installs Project| BUR-HAN Garden & Lawn Care";
   document.title = newTitle;

   const newDescription =
   "Discover our upcoming turf installs projects and how we provide top-notch care for your outdoor spaces. Stay tuned for expert lawn care services tailored to enhance the beauty and health of your lawn.";
   const descriptionMetaTag = document.querySelector('meta[name="description"]');
   if (descriptionMetaTag) {
     descriptionMetaTag.setAttribute("content", newDescription);
   } else {
     const newMetaTag = document.createElement("meta");
     newMetaTag.setAttribute("name", "description");
     newMetaTag.setAttribute("content", newDescription);
     document.head.appendChild(newMetaTag);
   }
 
   const newKeywords = [
    "turf installs, turf installs services, outdoor care, lawn maintenance, landscaping services, expert lawn care, lawn care projects",
  ].join(", ");
   const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
   if (keywordsMetaTag) {
     keywordsMetaTag.setAttribute("content", newKeywords);
   } else {
     const newMetaTag = document.createElement("meta");
     newMetaTag.setAttribute("name", "keywords");
     newMetaTag.setAttribute("content", newKeywords);
     document.head.appendChild(newMetaTag);
   }
 
   const ogTitleTag = document.querySelector('meta[property="og:title"]');
   if (ogTitleTag) {
     ogTitleTag.setAttribute("content", newTitle);
   } else {
     const newOgTitleTag = document.createElement("meta");
     newOgTitleTag.setAttribute("property", "og:title");
     newOgTitleTag.setAttribute("content", newTitle);
     document.head.appendChild(newOgTitleTag);
   }
 
   const ogDescriptionTag = document.querySelector('meta[property="og:description"]');
   if (ogDescriptionTag) {
     ogDescriptionTag.setAttribute("content", newDescription);
   } else {
     const newOgDescriptionTag = document.createElement("meta");
     newOgDescriptionTag.setAttribute("property", "og:description");
     newOgDescriptionTag.setAttribute("content", newDescription);
     document.head.appendChild(newOgDescriptionTag);
   }
 }, [location.pathname]);

  return (
    <div className="wrapper">
      <Header />

      <div className="projectsGalleryHeader">
        <h1 data-aos="zoom-in"> Turf Installs</h1>
      </div>
      <div className="projectsGalleryMain" data-aos="fade-up">
        <h2>COMING SOON!</h2>
        <a href="/our-projects" className="to-top">
          Back to projects
        </a>

        <img
          src={beesFlying}
          style={{ margin: "auto", display: "block" }}
          className="beeGif"
          alt="honeycomb"
        />
        {/* <h2>     
Click here to learn more about our services       
        <a
           href="/hardscapeinstalls"

          className="projectsLink"
        >
          Learn More About Our Services
        </a>
        </h2>    
        <hr />
        <a href="/our-projects" className="to-top">Back to projects</a>

        <div className="gallery">
         <Gallery photos={photosLawnCare} onClick={openLightbox}    
/>
      <ModalGateway >
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel 
 currentIndex={currentImage}
 views ={photosLawnCare.map(x => ({
    ...x,
    srcset: x.srcSet,
    caption: x.title,
    
 }))}
/>
          </Modal>
        ) : null}
      </ModalGateway> 
    
      </div> */}
        <Footer />
      </div>
    </div>
  );
}
