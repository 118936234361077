import React, { useEffect, useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  testiMonials,
  servicesOption,
  TestiMonialsDetails,
  options,
  selectCity,
  hearaboutusOption,
  styles,
} from "../../../script";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import "../style.css";

import burhanTeam from "../../../assets/img/burhan-team.png";
import northShoreReadersChoiceBadge from "../../../assets/img/partners/north-shore-readers-choice-badge-2023.png";
import consumerChoiceAward2024Badge from "../../../assets/img/partners/consumer-choice-award-badge-2024.png";
import greatPlaceToWorkBadge from "../../../assets/img/partners/great-place-to-work-badge-2024-2025.png";
import beeSafeLogo from "../../../assets/img/partners/bee-safe-pesticide-free-badge.png";
import contractorCheckBadge from "../../../assets/img/partners/contractorcheck-accredited-badge.png";
import homeStarsBadge from "../../../assets/img/partners/homestars-trusted-professional-badge.png";
import icpiCertifiedBadge from "../../../assets/img/partners/icpi-certified-concrete-paver-badge.png";
import redSealCertification from "../../../assets/img/partners/red-seal-certification-badge.png";

const animatedComponents = makeAnimated();

export default function WestVancouver() {
  
  const location = useLocation();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const history = useNavigate(); // Get the history object from React Router
  const form = useRef();

  // Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca${location.pathname}`;
    const linkTag = document.querySelector('link[rel="canonical"]');
    if (linkTag) {
      linkTag.setAttribute("href", canonicalUrl);
    } else {
      const newLinkTag = document.createElement("link");
      newLinkTag.setAttribute("rel", "canonical");
      newLinkTag.setAttribute("href", canonicalUrl);
      document.head.appendChild(newLinkTag);
    }

    const newTitle = "West Vancouver Landscaping Services | BUR-HAN Garden & Lawn Care";
    document.title = newTitle;

    const newDescription =
    "Transform your outdoor space with expert landscaping services from BUR-HAN Garden & Lawn Care in West Vancouver. We offer residential and commercial landscaping, including lawn care, hardscape installations, and more.";
    const descriptionMetaTag = document.querySelector('meta[name="description"]');
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", newDescription);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "description");
      newMetaTag.setAttribute("content", newDescription);
      document.head.appendChild(newMetaTag);
    }

    const newKeywords = [
      "West Vancouver landscaping, Landscaping companies West Vancouver, West Vancouver landscaping services, West Vancouver landscape company, Eco landscaping West Vancouver, Landscape design West Vancouver, Landscaping services West Vancouver, Garden maintenance West Vancouver,Residential landscaping West Vancouver, Hardscape installation West Vancouver, West Vancouver landscape contractors, West Vancouver landscaping companies, Top landscaping services in West Vancouver, West Vancouver residential landscaping experts, Custom garden maintenance in West Vancouver, Hardscape and softscape professionals in West Vancouver, Eco-friendly landscaping in West Vancouver BC, Year-round landscaping in West Vancouver, Best landscape contractors in West Vancouver, Affordable landscaping services near West Vancouver, Custom outdoor space designs in West Vancouver, Hardscape and garden care in West Vancouver, Seasonal cleanup and maintenance in West Vancouver, Lawn care and irrigation specialists in West Vancouver"
    ].join(", ");
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.setAttribute("content", newKeywords);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "keywords");
      newMetaTag.setAttribute("content", newKeywords);
      document.head.appendChild(newMetaTag);
    }

    const ogTitleTag = document.querySelector('meta[property="og:title"]');
    if (ogTitleTag) {
      ogTitleTag.setAttribute("content", newTitle);
    } else {
      const newOgTitleTag = document.createElement("meta");
      newOgTitleTag.setAttribute("property", "og:title");
      newOgTitleTag.setAttribute("content", newTitle);
      document.head.appendChild(newOgTitleTag);
    }

    const ogDescriptionTag = document.querySelector('meta[property="og:description"]');
    if (ogDescriptionTag) {
      ogDescriptionTag.setAttribute("content", newDescription);
    } else {
      const newOgDescriptionTag = document.createElement("meta");
      newOgDescriptionTag.setAttribute("property", "og:description");
      newOgDescriptionTag.setAttribute("content", newDescription);
      document.head.appendChild(newOgDescriptionTag);
    }
  }, [location.pathname]);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_TEMPLATE_ID,
        process.env.REACT_APP_SERVICE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          setShowSuccessMessage(true); // Show the popup message
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  };

  const handleReload = () => {
    setShowSuccessMessage(false); // Hide the popup message
    history("/"); // Navigate to the homepage
    window.location.reload(); // Reload the page
  };

  return (
    <div className="wrapper">
      <Header />
      <div className="serviceHeader">
        <h1>West Vancouver</h1>
      </div>
      <div className="serviceMain" data-aos="fade-up">
        <p>
          <strong>
            Servicing: Residential : Lawn & Garden Care / Commercial Maintenance
            / Snow Management / Projects / Clean ups
          </strong>
        </p>
        <hr />
        <p className="joinp">
          At BUR-HAN Garden and Lawn Care we put our clients first. Every
          individual of our highly skilled team truly cares about each property
          they set foot on. We know they do, because that’s part of the job
          requirements. Taking great care of our team translates to our
          customers receiving the best service in the industry.
        </p>
        {/* -------------- ABOUT -------------- */}
        <section id="about" className="about">
          <h2 className="section-header mb1 nodash">
            Make your landscaping project a true success
          </h2>
          <hr />
          <div className="row writtenContent">
            <div
              className="col-lg-6 order-1 order-lg-2 px-5 "
              data-aos="fade-left"
            >
              <img src={burhanTeam} alt="" className="img-fluid aboutImg" />
            </div>
            <div
              className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content"
              data-aos="fade-right"
            >
              <div className="corner-left">
                <div className="title" data-aos="zoom-in">
                  <h3>BUR-HAN GARDEN & LAWN CARE</h3>
                </div>
                <div
                  className="aboutSentence "
                  data-aos="fade-right"
                  data-duration="2000"
                >
                  <p>
                    {" "}
                    BUR-HAN Garden & Lawn Care has been providing Residential &
                    Commercial landscaping services for over 28 years. It is our
                    goal to provide our clients with the best possible
                    landscaping in Vancouver. We are passionate about creating
                    outdoor environments that inspire and captivate. With our
                    unwavering commitment to excellence, we go above and beyond
                    to exceed our partners' expectations. From the initial
                    consultation to the final touches, our experienced team
                    works closely with you, ensuring that every detail aligns
                    with your vision and preferences. With our comprehensive
                    range of services, including landscape design, installation,
                    and maintenance, we have the expertise to handle projects of
                    any size and complexity. Whether you envision a lush garden
                    retreat, a captivating hard scape, or a sustainable
                    landscape design, our dedicated professionals are here to
                    bring your dreams to life.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* -------------- END OF ABOUT -------------- */}
        {/* -------------- QUOTELINE-------------*/}
        <section id="quoteLine" className="quoteLine">
          <div className="content">
            <h3>
              {" "}
              Whether you need routine maintenance, a complete overhaul, or
              anything in between, we're here to turn your dreams into reality.
            </h3>
            <a href="/contactus" className="buttonQuoteLine">
              GET A QUOTE
            </a>
          </div>
        </section>
        {/* -------------- END OF QUOTELINE -------------*/}
        {/* --------------  SERVICES -------------- */}
        <section id="services" className="services section-bg">
          <h2 className="section-header" data-aos="fade-down">
            Our Services
          </h2>
          <p data-aos="zoom-in">
            "We offer excellent services, adapting to a wide range of
            preferences and requirements, ensuring your landscape achieves its
            utmost potential while simultaneously enhancing your property
            value."
          </p>
          <div className="row">
            <div
              className="col-lg-4 md-3 col-sm-6 col-xs-12"
              data-aos="fade-right"
            >
              <a href="/landscapemaintenance">
                <div className="servicesCard">
                  <div className="cover item-a">
                    <h3>Landscape Maintenance</h3>
                    <div className="card-back">
                      <ul>
                        <li>Full-Service Landscape Maintenance</li>
                        <li>Garden overhauls or one-time cleanups</li>
                        <li>Water bags for establishing trees</li>
                        <li>Tree staking</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div
              className="col-lg-4 md-3 col-sm-6 col-xs-12"
              data-aos="fade-down"
            >
              <a href="/hardscapeinstallsmaintenance">
                <div className="servicesCard">
                  <div className="cover item-b">
                    <h3>Hardscape Installs/Maintenance</h3>
                    <div className="card-back">
                      <ul>
                        <li>Paver/slab patios and walkways</li>
                        <li>Modular block walls</li>
                        <li>
                          New Irrigation Installations with New Landscapes
                        </li>
                        <li>Grading and drainage solutions</li>
                        <li>Gravel/river rock installation</li>
                        <li>Excavation</li>
                        <li>Landscape lighting</li>
                        <li>Water features</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div
              className="col-lg-4 md-3 col-sm-6 col-xs-12"
              data-aos="fade-left"
            >
              <a href="/designandmodelling">
                <div className="servicesCard">
                  <div className="cover item-c">
                    <h3>Designs and Modelling</h3>
                    <div className="card-back">
                      <ul>
                        <li>Full Design</li>
                        <li>3D rendering</li>
                        <li>Irrigation/ Lightning</li>
                        <li>Basic Design Sketchers</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div
              className="col-lg-4 md-3 col-sm-6 col-xs-12"
              data-aos="fade-right"
            >
              <a href="/residentiallawncare">
                <div className="servicesCard">
                  <div className="cover item-d">
                    <h3>Residential Lawn Care</h3>
                    <div className="card-back">
                      <ul>
                        <li>Weekly lawn mowing/edging</li>
                        <li>Fertilizing</li>
                        <li>Liming</li>
                        <li>Aeration</li>
                        <li>Moss Control</li>
                        <li>Organic Weed Control Program</li>
                        <li>Topdressing</li>
                        <li>Overseeding</li>
                        <li>Chafer beetle mitigation</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div
              className="col-lg-4 md-3 col-sm-6 col-xs-12"
              data-aos="fade-up"
            >
              <a href="/softscapeinstallsmaintenance">
                <div className="servicesCard">
                  <div className="cover item-e">
                    <h3> Softscape Installs/Maintenance</h3>
                    <div className="card-back">
                      <ul>
                        <li>Planting plans including design</li>
                        <li>Garden bed plantings </li>
                        <li>Lawn installation</li>
                        <li>Hedge installations</li>
                        <li>Soil and Mulch installations</li>
                        <li>
                          Garden bed dividers including Bender board, Precision
                          edge, landscape ties
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div
              className="col-lg-4 md-3 col-sm-6 col-xs-12"
              data-aos="fade-left"
            >
              <a href="/snowmanagement">
                <div className="servicesCard">
                  <div className="cover item-f">
                    <h3>Snow Management</h3>
                    <div className="card-back">
                      <ul>
                        <li>Paving Stone Restoration</li>
                        <li>Paver Sealing </li>
                        <li>Paver and Slab Repair / Replacement</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </section>
        {/* -------------- END OF SERVICES -------------- */}
        {/* -------------- TESTIMONIALS --------------   */}
        <section id="testimonial" className="testimonials">
          <div className="container">
            <h2
              className="section-header"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              WHAT OUR CLIENTS ARE SAYING?{" "}
            </h2>
            <div className="text-center mb-5">
              <a
                href="https://www.google.com/search?q=burhan&oq=burhan&gs_lcrp=EgZjaHJvbWUqBggAEEUYOzIGCAAQRRg7MgYIARBFGEAyDQgCEC4YrwEYxwEYgAQyBwgDEC4YgAQyDQgEEC4YgwEYsQMYgAQyBwgFEC4YgAQyBwgGEAAYgAQyBggHEEUYPNIBCDIyNjRqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8#"
                className="testimonial-button"
              >
                Google Reviews
              </a>
              <a
                href="https://homestars.com/companies/198943-bur-han-garden-lawn-care?utm_source=google&utm_medium=cpc&utm_campaign=b2c-unbranded-sn-west-vancouver-dsa-2023&keyword=&matchtype=&utm_term=&utm_content=650228789924_c_&gad_source=1&gclid=CjwKCAiAuNGuBhAkEiwAGId4ats_5fYuQPc5zcARrhPSCu5Tj_DOd2epYn-qfcpsKJmmdQAgu5mrdRoCgDYQAvD_BwE"
                className="testimonial-button"
              >
                HomeStars Reviews
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <OwlCarousel
                id="customer-testimonoals"
                className="owl-carousel owl-theme"
                {...options}
              >
                {testiMonials.map((testiMonialDetail) => {
                  return (
                    <TestiMonialsDetails
                      testiMonialDetail={testiMonialDetail}
                      key={testiMonialDetail._key}
                    />
                  );
                })}
              </OwlCarousel>
            </div>
          </div>
        </section>
        {/* -------------- END OF TESTIMONIALS -------------*/}
        {/* -----------------  PARTNERS -----------------  */}
        <section id="partners" className="partners">
          <div className="container-partners">
            <div className="row d-flex align-items-center justify-content-center">
              <div className="partners-section-heading">
                <h3 data-aos="fade-down">BUR-HAN Garden & Lawn Care</h3>
                <h2 className="section-header" data-aos="fade-down">
                  Awards and Affiliations
                </h2>
              </div>
              <div className="col-1-5" data-aos="zoom-in">
                <img
                  src={northShoreReadersChoiceBadge}
                  className="img-fluid partners-img"
                  alt="North Shore News Readers' Choice Winner 2023"
                  style={{ width: "50%" }}
                />
              </div>
              <div className="col-1-5" data-aos="zoom-in">
                <img
                  src={consumerChoiceAward2024Badge}
                  className="img-fluid partners-img"
                  alt="Consumer Choice Award Winner 2024 Vancouver"
                  style={{ width: "45%" }}
                />
              </div>
              <div className="col-1-5" data-aos="zoom-in">
                <img
                  src={greatPlaceToWorkBadge}
                  className="img-fluid partners-img partner3"
                  alt="Great Place to Work Certified 2024-2025"
                  style={{ width: "35%" }}
                />
              </div>
              <div className="col-1-5" data-aos="zoom-in">
                <img
                  src={beeSafeLogo}
                  className="img-fluid partners-img partner4"
                  alt="Bee Safe Initiative - Be Pesticide Free Logo"
                  style={{ width: "70%" }}
                />
              </div>
              <div className="col-1-5" data-aos="zoom-in">
                <img
                  src={contractorCheckBadge}
                  className="img-fluid partners-img partner5"
                  alt="ContractorCheck Accredited Member Badge"
                  style={{ width: "70%" }}
                />
              </div>
              <div className="col-1-5" data-aos="zoom-in">
                <img
                  src={homeStarsBadge}
                  className="img-fluid partners-img partner6"
                  alt="HomeStars Trusted Professional Logo"
                  style={{ width: "90%" }}
                />
              </div>
              <div className="col-1-5" data-aos="zoom-in">
                <img
                  src={icpiCertifiedBadge}
                  className="img-fluid partners-img partner7"
                  alt="ICPI Certified Concrete Paver Installer"
                  style={{ width: "55%" }}
                />
              </div>
              <div className="col-1-5" data-aos="zoom-in">
                <img
                  src={redSealCertification}
                  className="img-fluid partners-img partner8"
                  alt="Interprovincial Standard Red Seal Certification"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
        </section>
        {/* -------------- END OF PARTNERS -------------*/}
        {/* -------------- CONTACT --------------Í */}
        <section id="contact" className="contact">
          <div className="container">
            <h2
              className="section-header whiteDash"
              data-aos="zoom-in"
              data-aos-duration="1500"
              style={{ color: "var(--white-color)" }}
            >
              Contact
            </h2>
            <div className="row" data-aos="fade-up" data-aos-duration="1500">
              <div className="col-lg-6">
                <div className="info-box mb-4">
                  <i class="fa-solid fa-location-dot"></i>
                  <h3>Our Address</h3>
                  <p>
                    Mailing Address : Suite #314 1641 Lonsdale Ave, North
                    Vancouver, BC, V7M 2J5
                  </p>
                  <p>
                    Shop Address : 2110 Front St, North Vancouver, BC, V7H 1A3
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="info-box mb-4">
                  <i className="fa-solid fa-envelope"></i>
                  <h3>Email Us</h3>
                  <a
                    href="mailto:gardenandlawncare@bur-han.ca"
                    style={{ textDecoration: "none" }}
                  >
                    <p>gardenandlawncare@bur-han.ca</p>
                  </a>
                  <br />
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="info-box mb-4">
                  <i className="fa-solid fa-phone"></i>
                  <h3>Call Us</h3>
                  <a href="tel:604-983-2687" style={{ textDecoration: "none" }}>
                    <p>(604) 983-2687</p>
                  </a>
                  <br />
                </div>
              </div>
            </div>
            <div className="row" data-aos="fade-up" data-aos-duration="2500">
              <div className="col-lg-6 ">
                <iframe
                  title="BUR-HAN Garden & Lawn Care Location Map"
                  style={{ height: "100%", width: "100%", border: "0" }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2601.4161203935946!2d-123.01846222309891!3d49.30640276907365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548670f2acf0caf5%3A0xa180b9dfb101ef53!2sBUR-HAN%20Garden%20%26%20Lawn%20Care!5e0!3m2!1sen!2sca!4v1707501402244!5m2!1sen!2sca"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  allowFullScreen
                ></iframe>{" "}
              </div>
              <div className="col-lg-6">
                <form
                  ref={form}
                  onSubmit={sendEmail}
                  className="php-email-form"
                >
                  <div className="row">
                    <div className="col-md-6 form-group mb-3">
                      <input
                        type="text"
                        name="firstName"
                        className="form-control"
                        id="firstName"
                        placeholder="First Name *"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mb-3">
                      <input
                        type="text"
                        name="lastName"
                        className="form-control"
                        id="lastName"
                        placeholder="Last Name *"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mb-3">
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        id="phone"
                        placeholder="Phone Number *"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mb-3">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Email *"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        name="address"
                        id="address"
                        placeholder="Address *"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        name="postalcode"
                        id="postalcode"
                        placeholder="PostalCode *"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mb-3">
                      <Select
                        id="select-city"
                        closeMenuOnSelect={true}
                        classNamePrefix="select-city"
                        className="select-city"
                        name="city"
                        placeholder="City *"
                        options={selectCity}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        styles={styles}
                        isSearchable={false}
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mb-3">
                      <Select
                        id="services"
                        isMulti
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        classNamePrefix="select"
                        className="select"
                        name="services"
                        placeholder="Services *"
                        options={servicesOption}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        styles={styles}
                        isSearchable={false}
                        required
                      />
                    </div>
                    <div className="col-md-12 form-group mb-3">
                      <Select
                        id="hearaboutus"
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        classNamePrefix="select"
                        className="select"
                        name="hearaboutus"
                        placeholder="How did you hear about us? *"
                        options={hearaboutusOption}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        styles={styles}
                        isSearchable={false}
                        required
                      />
                    </div>
                  </div>
                  <textarea
                    id="requiredservices"
                    name="requiredservices"
                    className="col-md-12 form-group mb-3 form-control"
                    placeholder="Required Services *"
                    rows="4"
                    required
                  />
                  <div className="text-center">
                    <button type="submit" name="submit">
                      Get A Quote
                    </button>
                  </div>
                </form>
                {/* Popup form message */}
                {showSuccessMessage && (
                  <div className="popup active">
                    <div className="popup-content">
                      <i
                        className="fa fa-check main-content__checkmark"
                        id="checkmark"
                      ></i>
                      <h2>Thank you for contacting us!</h2>
                      <h3>
                        We'll get back to you as soon as possible. <br />
                        Follow us on Social:
                      </h3>
                      <div className="social">
                        <a
                          href="https://www.facebook.com/bhgardenandlawncare"
                          className="social d-flex align-items-center"
                        >
                          <i className="bi bi-facebook"></i>
                        </a>
                        <a
                          href="https://www.instagram.com/bhgardenandlawncare"
                          className="social d-flex align-items-center"
                        >
                          <i className="bi bi-instagram"></i>
                        </a>
                        <a
                          href="https://www.youtube.com/user/readyforvideo"
                          className="social d-flex align-items-center"
                        >
                          <i className="bi bi-youtube"></i>
                        </a>
                        <a
                          href="https://www.pinterest.ca/bhgardenandlawn"
                          className="social d-flex align-items-center"
                        >
                          <i className="bi bi-pinterest"></i>
                        </a>
                      </div>
                      <button onClick={handleReload}>
                        <span>Back to the Homepage </span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        {/* -------------- END OF CONTACT -------------*/}
      </div>
      <Footer />
    </div>
  );
}
